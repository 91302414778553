@import "../../Colors.less";

.wrapperModal:extend(.myPolice all) {
  position: absolute;
  top: 8%;
  bottom: 0%;
  background-color: black;
  padding: 10px 10px;
  margin: 10px;
  border: 1px solid @primary;

  .AdminStuffModal {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    height: 100%;
    overflow-y: scroll;
  }
  .userChoice {
    margin-top: 10px;
  }

  .datas {
    margin: 2vh 0;
    max-width: 98%;
    .preTxt {
      color: gray;
      max-height: 30vh;
      overflow: scroll;
    }
  }

  button {
    margin: 6px;
  }
  .bottom {
    margin-top: auto;
  }
  .closeBtn {
    margin-left: auto;
  }

  .MuiAccordion-root {
    max-width: 90%;
    margin: 10px 0px;
  }
}
