@import "../../Colors.less";

.reviveWrapper:extend(.myPolice all) {
  .countDown {
    color: @rougeNegatif;
    text-align: center;
    font-size: 20px;
    margin-bottom: 5px;
  }
  .reviveBtn {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
