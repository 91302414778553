.skillWrapper {
  display: -webkit-flex;
  display: flex;
  .cooldown {
    position: relative;
    left: -62px;
    margin-top: 3px;
    margin-right: -60px;
  }
  .actionButton {
    background-color: rgba(90, 167, 219, 0.5) !important;
    margin: 5px;
    .actionButtonSVG {
      height: 32px;
      width: 32px;
    }
    &.disabled {
      -webkit-filter: grayscale(1);
              filter: grayscale(1);
    }
  }
}
