@import "../../Colors.less";

.skillsWrapper:extend(.myPolice all) {
  .settings {
    .settingSVG {
      height: 7vh;
      width: 7vh;
    }
  }
}
