@import "Fonts/fonts.less";

/* Colors */
@primary: #eebc19;
@secondary: #0074aa;
@error: #e40f0f;
@background: black;
@backgroundLighter: #363636;
@vertPositif: #32af3b;
@rougeNegatif: #ff4b4b;
@scroll-bar-color: #3ea742;

.myPolice {
  font-family: "Chakra Petch";
}
