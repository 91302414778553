@import "../../Colors.less";

.wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;

  .iconApp {
    height: 30vh;
    width: 30vh;
  }

  .title:extend(.myPolice all) {
    color: white;
    font-size: 12vmin;
    margin-top: 5vh;
  }
  .subtitle:extend(.myPolice all) {
    color: white;
    margin-bottom: 15px;
    text-align: center;
  }

  .flexRow {
    display: -webkit-flex;
    display: flex;
  }

  .form {
    border: 1px solid #444445;
    padding: 10vmin 5vmin;
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 5vh;
    margin-bottom: 10vh;
    margin-left: 2vw;
    margin-right: 2vw;
    button {
      margin: 0 2vmin;
    }
  }
}
