@import "../../Colors.less";

.deleteDialog:extend(.myPolice all) {
  z-index: 100000000;
  .MuiDialogContentText-root {
    text-align: center;
  }
  .hilightDelete {
    color: aqua;
    font-weight: bold;
  }
}
