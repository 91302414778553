@import "../../Colors.less";

.inventaireContainer {
  padding: 5vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  .player {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 40 1;
            flex: 40 1;
    height: 100%;
    border: 3px solid grey;
    border-radius: 10px;
    -webkit-justify-content: space-between;
            justify-content: space-between;

    .playerIconWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      .playerIcon {
        height: 20vh;
      }
    }

    .armes {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
    }
  }

  .inventaire {
    -webkit-flex: 60 1;
            flex: 60 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 2vh;
    .moreInventPlace {
      width: 100%;
      max-height: 60px;
      font-weight: bold;
      span {
        margin-left: 8px;
      }
      .canAfford {
        color: @vertPositif;
      }
      .cantAfford {
        color: @rougeNegatif;
      }
    }
  }
  .slot {
    height: 7vh;
    width: 7vh;
    margin: 10px;
    border-radius: 15px;
    border: 2px solid #60a5e2;
    background-color: #2d444e;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    &.selectedSlot {
      border-color: @vertPositif;
    }
    .itemIcon {
      width: 5.5vh;
      height: 5.5vh;
    }
  }
}

.itemMenuWrapper:extend(.myPolice all) {
  .MuiPaper-root {
    max-width: 60%;
  }
  .menuItemIcon {
    width: 1.5em;
    height: 1.5em;
  }
  .dataWrapper {
    padding: 0 2vh;
    .starScoreSVG {
      width: 0.8em;
      height: 0.8em;
    }
    .itemTitle {
      font-weight: bold;
      text-decoration: underline;
      margin-bottom: 5px;
      font-size: 18px;
    }
    .effects {
      color: blueviolet;
      font-weight: bold;
      font-size: 16px;
    }
    .itemDescription {
      font-style: italic;
      font-size: 12px;
      color: antiquewhite;
      margin: 5px 0;
    }
  }
}
