.mapContainer {
  height: 100%;
}

// Thug life
.leaflet-control-attribution {
  display: none;
}

.sanbox {
  color: white;
}
.map {
  height: 100%;
  width: 100%;
}
.debug-coord {
  z-index: 1000;
  right: 0;
  padding: 3px;
  background-color: #f19898;
  color: white;
  position: absolute;
}

/** For toats */
.toastStrong {
  color: aqua;
  font-weight: bold;
}
