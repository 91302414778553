@import "../../Colors.less";

.NameTag:extend(.myPolice all) {
  display: -webkit-flex;
  display: flex;
  color: white;
  -webkit-align-items: center;
          align-items: center;
  .pseudo {
    max-width: 30vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.warningModalText:extend(.myPolice all) {
  margin-bottom: 20px;
}

.encart_id:extend(.myPolice all) {
  margin-top: 2vh;
  .id {
    background-color: rgb(2, 146, 146);
    padding: 5px;
    border-radius: 8px;
  }
}
.copiedTxt {
  background-color: gray;
  padding: 3px;
  border-radius: 3px;
}
