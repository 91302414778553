@import "../../../Colors.less";

.fightModalWrapper {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

  .modalHeader {
    -webkit-flex: 30 1;
            flex: 30 1;
    display: -webkit-flex;
    display: flex;
    z-index: 100;
    .playerSide,
    .monsterSide {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 50 1;
              flex: 50 1;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      .name:extend(.myPolice all) {
        display: -webkit-flex;
        display: flex;
        -webkit-align-self: start;
                align-self: start;
        font-size: 18px;
        -webkit-align-items: center;
                align-items: center;
        font-weight: bold;
        text-decoration: overline;
        font-style: italic;
        margin-top: 3vh;
      }
      .monsterName {
        -webkit-align-self: end;
                align-self: end;
      }
      .equipedShield {
        height: 5vh;
        width: 5vh;
        margin-left: -5vh;
      }
      .fightProtagonists {
        &.dead {
          -webkit-animation: dying 5s;
                  animation: dying 5s;
          /* Permet de rester dans l'état de fin de l'animation */
          -webkit-animation-fill-mode: forwards;
                  animation-fill-mode: forwards;
        }
        &.monsterSize {
          height: 13vh;
          width: 13vh;
        }
        height: 15vh;
        width: 15vh;
      }
    }
    .monsterSide.nrv {
      -webkit-animation: nrv 4s;
              animation: nrv 4s;
      /* Permet de rester dans l'état de fin de l'animation */
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      .monsterName {
        color: @rougeNegatif;
      }
    }
  }

  .fightModalContent:extend(.myPolice all) {
    -webkit-flex: 70 1;
            flex: 70 1;
    display: -webkit-flex;
    display: flex;
    background-color: black;
    padding: 1vh 2vh;
    -webkit-flex-direction: column;
            flex-direction: column;
    .fullHeight {
      height: 100%;
    }
    .svgBtnWrapper {
      -webkit-align-self: center;
              align-self: center;
      margin-right: 10px;
      margin: 1vh;
      min-width: 30vh;
      .svgFight {
        width: 4vh;
        height: 4vh;
        padding: 1vh;
      }
      .coward {
        font-size: 9px;
        text-transform: lowercase;
        margin-left: 4px;
      }
    }
    .txtAndNextWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      height: 40vh;
      padding: 6vh 4vh;
      .react-typewriter-text-wrap {
        height: 100%;
      }
    }
  }

  .monsterSpecs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 25 1;
            flex: 25 1;

    .stamina {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 50 1;
              flex: 50 1;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column;
    }
    .datas {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-flex: 50 1;
              flex: 50 1;
      .line {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        width: 100%;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        .label {
          margin-right: 1vh;
        }
        .middle {
          margin-left: auto;
        }
        .dataFightIcon {
          height: 4vh;
          width: 4vh;
        }
      }
    }
  }

  .logs {
    -webkit-flex: 50 1;
            flex: 50 1;
    padding: 1.5vh 0;
    background-color: #161616;
    border-radius: 8px;
    border: 1px solid gray;
    overflow-y: auto;
    max-height: 30vh;

    .logLine:extend(.myPolice all) {
      padding: 0 1.5vh;
      color: #c1c1c1;
      &.goodLog {
        color: #8ec184;
      }
      &.badLog {
        color: #cf9090;
      }
      .logUsername {
        color: #e1b30d;
        font-weight: bold;
      }
      .logMonstername {
        color: @rougeNegatif;
        font-weight: bold;
      }
      .number {
        font-weight: bold;
        font-size: 1.12rem;
        &.critique {
          text-shadow: 0px 0px 6px #ff9100, 0 0 2px white;
        }
      }
    }
    .logLineDivider {
      padding: 1.5vh 4vh;
    }
  }

  .userActions {
    -webkit-flex: 25 1;
            flex: 25 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 3vh;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
}

.fightRootModal {
  .MuiDrawer-paper {
    height: 92% !important; /** Overides ModalBottom **/
  }
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.92);
  }
}

@-webkit-keyframes dying {
  0% {
    -webkit-filter: none;
            filter: none;
  }
  40% {
    -webkit-filter: grayscale(0.8) blur(4px);
            filter: grayscale(0.8) blur(4px);
  }
  100% {
    -webkit-filter: grayscale(1) blur(110px);
            filter: grayscale(1) blur(110px);
  }
}

@keyframes dying {
  0% {
    -webkit-filter: none;
            filter: none;
  }
  40% {
    -webkit-filter: grayscale(0.8) blur(4px);
            filter: grayscale(0.8) blur(4px);
  }
  100% {
    -webkit-filter: grayscale(1) blur(110px);
            filter: grayscale(1) blur(110px);
  }
}

@-webkit-keyframes nrv {
  0% {
    -webkit-filter: none;
            filter: none;
  }
  100% {
    -webkit-filter: drop-shadow(0px 0px 18px red);
            filter: drop-shadow(0px 0px 18px red);
  }
}

@keyframes nrv {
  0% {
    -webkit-filter: none;
            filter: none;
  }
  100% {
    -webkit-filter: drop-shadow(0px 0px 18px red);
            filter: drop-shadow(0px 0px 18px red);
  }
}
