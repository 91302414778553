@import "../../Colors.less";

.skinsSelectorWrapper:extend(.myPolice all) {
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: @backgroundLighter;
  border: 1px solid @primary;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 95vw;

  .skinHorizontalScroll {
    display: -webkit-flex;
    display: flex;
    overflow-y: auto;
    width: 90vw;

    .skinCard {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      padding: 2vh;
      .selected {
        color: @vertPositif;
      }
      .name {
        margin: 1vh 0;
      }
      .skinImg {
        width: 13vh;
        height: 13vh;

        &.locked {
          -webkit-filter: brightness(0);
                  filter: brightness(0);
        }
      }
    }
  }
}
