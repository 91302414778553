@import "../../../Colors.less";

.pnjModalWrapper {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  .iconPnjWrapper {
    -webkit-flex: 40 1;
            flex: 40 1;
    display: -webkit-flex;
    display: flex;
    z-index: 100;
    .pnjName:extend(.myPolice all) {
      display: -webkit-flex;
      display: flex;
      font-size: 24px;
      -webkit-align-items: center;
              align-items: center;
      font-weight: bold;
      text-decoration: overline;
      font-style: italic;
    }
    .iconPNJ {
      height: 20vh;
      width: 20vh;
    }
  }
  .modalContent {
    -webkit-flex: 60 1;
            flex: 60 1;
    background-color: black;
    margin-top: -3vh;
    padding: 5vh 0vh;
  }
  .txtWrapper:extend(.myPolice all) {
    height: 100%;
    padding: 0 4vh;
    .arrowRightWrapper {
      width: 7vh;
      height: 7vh;
      -webkit-align-self: end;
              align-self: end;
      .arrowRight {
        width: 7vh;
        height: 7vh;
      }
    }
    .txtAndNextWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      height: 100%;
      .react-typewriter-text-wrap {
        height: 100%;
      }
    }
  }
}
