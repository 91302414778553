@import "../../../../../Colors.less";

.wrapperFlask {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background: transparent;
  .flaskIcon {
    height: 20vh;
    width: 20vh;
    background-repeat: repeat-x;
    background-position: 0 4cm;
    background-color: transparent;
    background-image: url("../../../../../SVG/liquidFilling.png");

    &.active {
      background-position: 40cm 1cm;
      background-image: url("../../../../../SVG/liquidFilling.png");
    }
  }
}
