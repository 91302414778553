@import "../../Colors.less";

.LoadingFullSize {
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: @background;
}

.loadingGIF {
  width: 70vw;
  height: 70vw;
}
