.skills {
  bottom: 5px;
}
.zooms {
  top: 0.4vh;
}
.layers {
  bottom: 0.4vh;
  margin-left: 82vw;
}
.skills,
.layers,
.zooms {
  position: absolute;
  z-index: 10000;
  .actionButton {
    background-color: rgba(90, 167, 219, 0.5) !important;
    /* background-color: rgba(159, 190, 211, 0.5) !important; */
    margin: 5px;
    .actionButtonSVG {
      height: 32px;
      width: 32px;
    }
    &.disabled {
      -webkit-filter: grayscale(1);
              filter: grayscale(1);
    }
  }
  .layerSVG {
    color: #dfdfdf;
  }
}
