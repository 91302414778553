@import "../../Colors.less";

.menuWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 10%;
}

.menu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  width: 100%;
  color: white;
  .badge:extend(.myPolice all) {
    position: absolute;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    top: 0.5vh;
    right: 0.5vh;
    min-width: 1em;
    padding: 0.3em;
    background-color: red;
    border-radius: 5000px;
  }
}

.icon {
  height: 10vw;
  max-height: 6vh;
  max-width: 6vh;
  width: 10vw;
  padding: 2vw;
}
.imageWrapper {
  position: relative;
}
.imageWrapper.active {
  padding: 0px;
  border-radius: 50%;
  box-shadow: 0 0 40px #6b6b6b;
  background-color: #252525;
}
.icon.active {
  height: 12vw;
  width: 12vw;
}
