@import "./Colors.less";

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: @background;
}

.AppWrapper {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin: auto;
}

.fullScreenWrapper {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: @background;
}
.react-swipeable-view-container {
  height: 100%;
}

.swipeablesContainer {
  height: 82%;
  width: 100%;
  color: white;
}

.swipeView {
  width: 100%;
  height: 100%;
}

/**
* GOLD STUFF
*/
.okGold {
  color: @vertPositif;
}
.koGold {
  color: @rougeNegatif;
}
.goldPriceWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.goldPriceIcon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.custom-scrollbar {
  /* Firefox */
  scrollbar-width: thin; /* NOSONAR: Valide à partir de FF64 */
  scrollbar-color: @scroll-bar-color transparent; /* NOSONAR: Valide à partir de FF64 */

  /* Chrome */
  &::-webkit-scrollbar {
    width: 2vh;
  }
  &::-webkit-scrollbar-thumb {
    background-color: @scroll-bar-color;
    border-radius: 9999px;
    border: 0.7vh solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    margin: 0.2vh;
  }
}
