@import "../../Colors.less";

.shop {
  padding: 0vh 2vh;
  .shopLabel:extend(.myPolice all) {
    margin-top: 5px;
  }
  .shopWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    .slotWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
    }
    .green {
      color: @vertPositif;
    }
    .slot {
      height: 7vh;
      width: 7vh;
      margin: 10px;
      border-radius: 15px;
      border: 2px solid #e2bf60;
      background-color: #4e4b2d;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;

      &.selectedSlot {
        border-color: @vertPositif;
      }
      .itemIcon {
        width: 5.5vh;
        height: 5.5vh;
      }
    }
  }
}
