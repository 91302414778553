@import "../../Colors.less";

.modalSettingsTitle:extend(.myPolice all) {
  text-align: center;
}
.settingsWrapper {
  height: 100%;
  background-color: #0e0e0e;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  .setting {
    display: -webkit-flex;
    display: flex;
    margin: 1vh;

    .settingsIcon {
      height: 6.5vh;
      width: 6.5vh;
    }
  }
}
