@import "../../Colors.less";

.infosWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 8%;
  color: white;
  .HUDIcon {
    height: 5vw;
    width: 5vw;
    max-height: 5vh;
    max-width: 5vh;
  }

  .levelAndName {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .hpxpAndName:extend(.myPolice all) {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 60 1;
            flex: 60 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0.5vh;

    .hpAndxp {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;

      .pvIcon {
        margin-right: 1vh;
        margin-top: 5px;
      }
      .progressBarWrapper {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
        .hp {
          .hpBar {
            width: 100%;
            margin-right: 2vw;
          }
          width: 100%;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center;
        }
        .xp {
          width: 100%;
          margin-bottom: 3px;
        }
        .pvTxt {
          font-size: 10px;
        }
      }
    }
  }

  .stats:extend(.myPolice all) {
    display: -webkit-flex;
    display: flex;
    padding: 1vh;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex: 10 1;
            flex: 10 1;
    .statWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
    }
    .statTxt {
      margin-left: 1vh;
      min-width: 33px;
    }
  }

  .gold {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 30 1;
            flex: 30 1;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    padding: 2vw;

    .red {
      color: red;
    }
    .green {
      color: green;
    }
    .goldTxts {
      height: 32px;
      white-space: nowrap;
    }
    .goldDelta:extend(.myPolice all) {
      margin-right: 2vw;
      text-align: right;
      margin-top: -5px;
      font-weight: bold;
      position: relative;
      white-space: nowrap;
      /*border: 1px solid red;
      /*position: absolute;
      right: 8vw; /** 6 (size icon) + 2 (margin) vw**/
    }
    .goldTxt:extend(.myPolice all) {
      font-weight: bold;
      font-size: 20;
      margin-right: 2vw;
      text-align: right;
    }
    .goldSvg {
      height: 6vw;
      width: 6vw;
      max-height: 5vh;
      max-width: 5vh;
    }
    .coinWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      margin-bottom: 11px;
    }
  }
}
